<template>
	<v-container>
		<v-row justify="center">
			<v-col cols="12" sm="4" md="5">
				<h1 class="text-center">Activa tu cuenta con un código</h1>
			</v-col>
		</v-row>
		<v-row justify="center">
			<v-col cols="12" sm="4" md="5">
				<form @submit.prevent="submit">
					<v-text-field
						v-model="name"
						:error-messages="nameErrors"
						:counter="50"
						label="Nombre"
						required
						@input="$v.name.$touch()"
						@blur="$v.name.$touch()"
					></v-text-field>
					<!-- <v-text-field
						v-model="username"
						:error-messages="usernameErrors"
						:counter="200"
						label="Usuario"
						required
						@input="$v.username.$touch()"
						@blur="$v.username.$touch()"
					></v-text-field> -->

					<v-text-field
						v-model="email"
						:error-messages="emailErrors"
						label="E-mail"
						required
						@input="$v.email.$touch()"
						@blur="$v.email.$touch()"
					></v-text-field>
					<v-text-field
						v-model="password"
						:error-messages="passwordErrors"
						:counter="20"
						label="Contraseña"
						required
						@input="$v.password.$touch()"
						@blur="$v.password.$touch()"
					></v-text-field>
					<v-text-field
						v-model="code"
						:error-messages="codeErrors"
						:counter="60"
						label="Código"
						required
						@input="$v.code.$touch()"
						@blur="$v.code.$touch()"
					></v-text-field>
					<!-- <vue-recaptcha
						ref="invisibleRecaptcha"
						@verify="onVerify"
						@expired="onExpired"
						size="invisible"
						:sitekey="sitekey"
					>
						<button>Click me</button>
					</vue-recaptcha> -->

					<!-- <div
						class="g-recaptcha"
						data-sitekey="6Le-ZbUgAAAAABH-LQ-OWBrAHBPx7DsE_P4JHXow"
					></div> -->
					<!-- <v-btn class="mr-4" @click="submit"> Activar </v-btn> -->
					<v-btn :loading="loading" class="mr-4" type="submit"> Activar </v-btn>
					<v-btn @click="clear"> clear </v-btn>
				</form>
			</v-col>
		</v-row>
	</v-container>
</template>
<script>
	// eslint-disable-line global-require
	// import { VueRecaptcha } from "vue-recaptcha";
	import { validationMixin } from 'vuelidate';
	import { required, maxLength, email } from 'vuelidate/lib/validators';
	// var MAXLENGTHGLOBAL = 100;

	// import { load } from "recaptcha-v3";
	// function onSubmit(token) {
	// 	e.preventDefault();
	// 	// document.getElementById("demo-form").submit();
	// 	console.log("test");
	// }
	export default {
		name: 'ActiveCode',
		mixins: [validationMixin],

		validations: {
			// username: { required, maxLength: maxLength(200) },
			code: { required, maxLength: maxLength(60) },
			name: { required, maxLength: maxLength(200) },
			email: { required, email },
			password: { required, maxLength: maxLength(200) },
			// select: { required },
			// checkbox: {
			// 	checked(val) {
			// 		return val;
			// 	},
			// },
		},
		// components: { VueRecaptcha },
		data: () => ({
			loading: false,
			username: '',
			code: '',
			name: '',
			email: '',
			password: '',
			select: null,
			items: ['Item 1', 'Item 2', 'Item 3', 'Item 4'],
			checkbox: false,
			sitekey: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
		}),

		computed: {
			checkboxErrors() {
				const errors = [];
				if (!this.$v.checkbox.$dirty) return errors;
				!this.$v.checkbox.checked &&
					errors.push('¡Debes estar de acuerdo para continuar!');
				return errors;
			},
			selectErrors() {
				const errors = [];
				if (!this.$v.select.$dirty) return errors;
				!this.$v.select.required && errors.push('Item is required');
				return errors;
			},
			nameErrors() {
				const errors = [];
				if (!this.$v.name.$dirty) return errors;
				!this.$v.name.maxLength &&
					errors.push(`El nombre debe tener como máximo 50 caracteres.`);
				!this.$v.name.required && errors.push('Nombre es requerido.');
				return errors;
			},
			passwordErrors() {
				const errors = [];
				if (!this.$v.password.$dirty) return errors;
				!this.$v.password.maxLength &&
					errors.push(`Contraseña debe tener como máximo 20 caracteres.`);
				!this.$v.password.required && errors.push('Contraseña es requerida.');
				return errors;
			},
			usernameErrors() {
				const errors = [];
				if (!this.$v.username.$dirty) return errors;
				!this.$v.username.maxLength &&
					errors.push(`El nombre debe tener como máximo 200 caracteres.`);
				!this.$v.username.required && errors.push('Usuario es requerido.');
				return errors;
			},
			codeErrors() {
				const errors = [];
				if (!this.$v.code.$dirty) return errors;
				!this.$v.code.maxLength &&
					errors.push(`El codigo debe tener como máximo 50 caracteres.`);
				!this.$v.code.required && errors.push('Código es requerido.');
				return errors;
			},
			emailErrors() {
				const errors = [];
				if (!this.$v.email.$dirty) return errors;
				!this.$v.email.email && errors.push('Debe ser un email válido');
				!this.$v.email.required && errors.push('E-mail es requerido');
				return errors;
			},
		},
		created() {
			// const recaptcha = this.$recaptchaInstance;
			// // Hide reCAPTCHA badge:
			// recaptcha.hideBadge();
			// // Show reCAPTCHA badge:
			// recaptcha.showBadge();
		},
		mounted() {
			if (this.$route.query.code) {
				this.code = this.$route.query.code;
			}
			if (this.$route.query.email) {
				this.email = this.$route.query.email;
			}
			if (this.$route.query.name) {
				this.name = this.$route.query.name;
			}
			// eslint-disable-next-line no-console
			console.log(this.$router, this.$route);
		},
		methods: {
			onEvent() {
				// when you need a reCAPTCHA challenge
				this.$refs.recaptcha.execute();
			},
			onSubmit: function () {
				this.$refs.invisibleRecaptcha.execute();
			},
			onVerify: function (response) {
				// eslint-disable-next-line no-console
				console.log('Verify: ' + response);
			},
			onExpired: function () {
				// eslint-disable-next-line no-console
				console.log('Expired');
			},
			resetRecaptcha() {
				this.$refs.recaptcha.reset(); // Direct call reset method
			},
			utf8_to_b64(str) {
				return window.btoa(unescape(encodeURIComponent(str)));
			},
			async submit(token) {
				// eslint-disable-next-line no-console
				console.log(token, this.$v, this.$v.$invalid);
				this.$v.$touch();
				if (!this.$v.$invalid) {
					try {
						this.loading = true;
						let result = await this.$http.post('/users/active', {
							name: this.name,
							username: this.email,
							code: this.code,
							email: this.email,
							password: this.$md5(
								this.$md5(this.utf8_to_b64(this.password)),
							),
							pass: this.password,
						});
						if (result.data) {
							window.resultlogin = result;
							this.$storage.set('token', result.data.token);

							this.$storage.set('data', result.data);

							this.$storage.set('user', result.data.username);
							this.loading = false;
							this.$router.push('/selecciona');
						}
						// eslint-disable-next-line no-console
						console.log(result);
					} catch (error) {
						this.showProgress = false;
						this.snackbar = true;
						this.loading = false;
						// eslint-disable-next-line no-console
						console.log(error);
					}
				}else{
					// eslint-disable-next-line no-console
					console.log('invalid',this.$v.$invalid)
				}
			},
			clear() {
				this.$v.$reset();
				this.name = '';
				this.code = '';
				this.username = '';
				this.password = '';
				this.email = '';
				this.select = null;
				this.checkbox = false;
			},
		},
	};
</script>
<style></style>
