const listaAreaConocimiento = [
    {
        name:'Salud',
        code:'salud',
        color1:'#4689bd',
        color2:'#73b0e1',
        order:1
    },
    {
        name:'Espiritualidad',
        code:'espiritualidad',
        color1:'#401686',
        color2:'#5a26ae',
        order:2
    },
    {
        name:'Recreación',
        code:'recreacion',
        color1:'#e08839',
        color2:'#e08839',
        order:3
    },
    
    
    {
        name:'Finanzas',
        code:'Finanzas',
        color1:'#f4e358',
        color2:'#ecd72d',
        order:6
    },
    {
        name:'Crecimiento Personal',
        code:'crecimiento_personal',
        color1:'#63b95d',
        color2:'#619b5c',
        order:5
    },
    {
        name:'Relación Interpersonal',
        code:'relacion_interpersonal',
        color1:'#bc2f2a',
        color2:'#de352f',
        order:4
    },
]
export default listaAreaConocimiento
