<template>
  <v-row class="mt-16">
    <v-col>
      <v-row justify="center">
        <v-col v-resize="onResize" cols="12" md="5" sm="6" lg="5">
          <iframe
            src="https://player.vimeo.com/video/445730196"
            frameborder="0"
            width="100%"
            allow="autoplay; fullscreen"
            allowfullscreen
            id="videoholter"
            :height="`${heightFrame}px`"
          ></iframe>
        </v-col>
        <v-col>
          <v-sheet light class="transparent">
            <h1>Holter de Niufleex</h1>
            <p>
              El Holter es un espacio virtual de coaching que te acompañará en
              tu proceso de aprendizaje, con la intención de colaborar en tu
              desarrollo integral, la definición de tu propósito de vida y en tu
              equilibrio espiritual, emocional, físico y cognitivo.
            </p>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="2">
          <v-divider class="niu-dividir-2 niu-bg-niufleex"></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="text-center" cols="12" md="5" sm="6" lg="5">
          <v-sheet light class="transparent">
          <h1>
            Tu rueda de la vida
            <v-icon
              class="niu-text size45"
              style="border:0 !important;color: #4d1552 !important;"
              >mdi-chevron-right</v-icon
            >
          </h1>
          </v-sheet>
        </v-col>
        <v-col>
          <v-sheet light class="transparent">
          <p>
            “La rueda de la vida” es una herramienta sencilla pero poderosa, un
            instrumento gráfico de altísimo valor que te concede la oportunidad
            de ser consciente del grado de satisfacción que experimentas en
            áreas importantes de tu vida, así como del equilibrio que existe
            entre ellas. Además, te muestra y describe tus áreas de fortaleza
            interna y de desarrollo potencial en los diferentes ámbitos de tu
            vida.
          </p>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row align="center" justify="center" class="mt-6">
        <v-col
          cols="10"
          md="5"
          lg="5"
          id="radarHolter"
          v-resize="onresizeholter"
        >
          <div
            :style="{
              position: 'absolute',
              width: '97%',
              height: `${radar}px`,
              borderRadius: '50%',
              background: 'rgb(228,118,41)',
              background:
                'linear-gradient(45deg, rgba(228,118,41,1) 0%, rgba(133,46,158,1) 100%)'
            }"
          ></div>
          <apexchart
            type="radar"
            :height="radar"
            :options="chartOptions"
            :series="series"
          ></apexchart>
        </v-col>
        <v-col cols="12" md="6" sm="7" lg="5" class="areas ml-4">
          <div
            v-for="(item, index) in conocimiento"
            :key="'chip' + index"
            style="display:block;"
          >
            <v-chip class="mr-16 ml-16 mb-3 pr-10 pl-10 elevation-2" light>
              <v-avatar>
                <v-icon :style="{ color: `${item.color1}` }"
                  >mdi-radiobox-marked</v-icon
                >
              </v-avatar>
              <small
                class="ml-1"
                style="font-size:22px !important;"
                :style="{ color: `${item.color2}` }"
                >{{ item.name }}</small
              >
            </v-chip>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-sheet light class="transparent">
          <h1 class="text-center">Nuestras sugerencias</h1>
          </v-sheet>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <v-expansion-panels
            accordion
            flat
            class="elevation-0"
            elevation="0"
            v-model="activePanel"
          >
            <v-expansion-panel
              v-for="(item, index) of holter"
              :key="`holter_${index}`"
            >
              <v-expansion-panel-header
                :style="{
                  'background-color': item.color,
                  color: 'white'
                }"
              >
                <span class="title">
                  {{ item.title }}
                </span>
              </v-expansion-panel-header>
              <keep-alive>
                <v-expansion-panel-content
                  eager
                  class="elevation-0"
                  elevation="0"
                  :style="{
                    'background-color': '#f5f5f5 !important',
                    color: 'white'
                  }"
                >
                  <v-row>
                    <v-col>
                      <apexchart
                        type="radar"
                        :height="300"
                        width="300"
                        :options="
                          chartMensajeAreas(colorsAreas[item.code], 300)
                        "
                        :series="series"
                      ></apexchart>
                    </v-col>
                    <v-col>
                      <v-card class="pa-3" light>
                        <v-card-text>
                          {{ item.description }}
                        </v-card-text>
                        <div
                          class="niu-barra"
                          :style="{
                            'background-color': item.color
                          }"
                        ></div>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </keep-alive>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
      <!-- <v-row>
        <v-col cols="12" md="5" lg="5" style="overflow:auto; height:500px">
          <v-row>
            <v-col cols="12">
              <v-card
                class="mx-auto pl-3 pr-3 pb-2"
                :style="{ backgroundColor: salud.color }"
                dark
              >
                <v-card-title>
                  <span class="title">
                    {{ salud.title }}
                  </span>
                </v-card-title>

                <v-card-text class="niu-text-card">
                  {{ salud.description }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                class="mx-auto pl-3 pr-3 pb-2"
                :style="{ backgroundColor: finanzas.color }"
                dark
              >
                <v-card-title>
                  <span class="title">
                    {{ finanzas.title }}
                  </span>
                </v-card-title>

                <v-card-text class="niu-text-card">
                  {{ finanzas.description }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                class="mx-auto pl-3 pr-3 pb-2"
                :style="{ backgroundColor: recreacion.color }"
                dark
              >
                <v-card-title>
                  <span class="title">
                    {{ recreacion.title }}
                  </span>
                </v-card-title>

                <v-card-text class="niu-text-card">
                  {{ recreacion.description }}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card
                class="mx-auto pl-3 pr-3 pb-2"
                :style="{ backgroundColor: interpersonal.color }"
                dark
              >
                <v-card-title>
                  <span class="title">
                    {{ interpersonal.title }}
                  </span>
                </v-card-title>

                <v-card-text class="niu-text-card">
                  {{ interpersonal.description }}
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->
    </v-col>
  </v-row>
</template>
<script>
import conocimiento from '../services/listaAreaConocimientoHolter'
import VueApexCharts from 'vue-apexcharts'
export default {
  mounted () {
    this.onResize()
    this.onresizeholter()
    this.activePanel = 0
  },
  created () {
    this.conocimiento = conocimiento
    this.$http
      .post('users/ruedaDeLaVida', {
        username: this.$storeNiu.getTextOrInt('user')
      })
      .then(response => {
        this.series = response.data.grafico
        try {
          this.holter.espiritualidad = {
            ...response.data.comentarios.filter(ele => {
              return ele.code == 'espiritual'
            })[0]
          }
          //eslint-disable-next-line
        } catch (error) {}

        try {
          this.holter.crecimiento = {
            ...response.data.comentarios.filter(ele => {
              return ele.code == 'crecimiento'
            })[0]
          }
          //eslint-disable-next-line
        } catch (error) {}

        try {
          this.holter.salud = {
            ...response.data.comentarios.filter(ele => {
              return ele.code == 'salud'
            })[0]
          }
          //eslint-disable-next-line
        } catch (error) {}
        try {
          this.holter.finanzas = {
            ...response.data.comentarios.filter(ele => {
              return ele.code == 'finanzas'
            })[0]
          }
          //eslint-disable-next-line
        } catch (error) {}
        try {
          this.holter.recreacion = {
            ...response.data.comentarios.filter(ele => {
              return ele.code == 'recreacion'
            })[0]
          }
          //eslint-disable-next-line
        } catch (error) {}
        try {
          this.holter.interpersonal = {
            ...response.data.comentarios.filter(ele => {
              return ele.code == 'interpersonal'
            })[0]
          }
          //eslint-disable-next-line
        } catch (error) {}
      })
      //eslint-disable-next-line
      .catch(err => {})
  },
  components: {
    apexchart: VueApexCharts
  },
  data: () => ({
    activePanel: null,
    conocimiento: [],
    heightFrame: 300,
    holter: {
      salud: {
        code: '',
        description: '',
        id: null,
        title: '',
        value: null
      },
      espiritualidad: {
        code: '',
        description: '',
        id: null,
        title: '',
        value: null
      },
      recreacion: {
        code: '',
        description: '',
        id: null,
        title: '',
        value: null
      },
      finanzas: {
        code: '',
        description: '',
        id: null,
        title: '',
        value: null
      },
      crecimiento: {
        code: '',
        description: '',
        id: null,
        title: '',
        value: null
      },

      interpersonal: {
        code: '',
        description: '',
        id: null,
        title: '',
        value: null
      }
    },
    series: [
      {
        name: 'Relación Interpersonal',
        data: [0, 0, 0, 0, 0, 0]
      },
      {
        name: 'Salud',
        data: [0, 0, 0, 0, 0, 0]
      },
      {
        name: 'Espiritualidad',
        data: [0, 0, 0, 0, 0, 0]
      },
      {
        name: 'Recreación',
        data: [0, 0, 0, 0, 0, 0]
      },
      {
        name: 'Finanzas',
        data: [0, 0, 0, 0, 0, 0]
      },
      {
        name: 'Crecimiento Personal',
        data: [0, 0, 0, 0, 0, 0]
      }
    ],
    chartOptions: {
      chart: {
        height: 900,
        type: 'radar',
        toolbar: {
          show: false
        }
      },
      // title: {
      //   text: ''
      // },
      stroke: {
        width: 1,
        colors: [
          '#FF0000',
          '#29ABE2',
          '#5505AA',
          '#F97C00',
          '#F9E02B',
          '#009245'
        ]
      },
      fill: {
        opacity: 0.9,
        colors: [
          '#FF0000',
          '#29ABE2',
          '#5505AA',
          '#F97C00',
          '#F9E02B',
          '#009245'
        ]
      },
      markers: {
        size: 0, //dejar en cero para que no aparezca un hover
        colors: [
          '#FF0000',
          '#29ABE2',
          '#5505AA',
          '#F97C00',
          '#F9E02B',
          '#009245'
        ]
      },
      xaxis: {
        labels: {
          show: false,
          rotate: -90,
          rotateAlways: true,
          hideOverlappingLabels: true,
          showDuplicates: false,
          trim: false,
          minHeight: undefined,
          maxHeight: 120,
          style: {
            colors: [
              '#FF0000',
              '#29ABE2',
              '#5505AA',
              '#F97C00',
              '#F9E02B',
              '#009245'
            ],
            fontSize: '12px',
            fontFamily: 'Helvetica, Arial, sans-serif',
            fontWeight: 400,
            cssClass: 'niu-radar-label'
          },
          offsetX: 0,
          offsetY: 0,
          datetimeUTC: true,
          datetimeFormatter: {
            year: 'yyyy',
            month: "MMM 'yy",
            day: 'dd MMM',
            hour: 'HH:mm'
          }
        },
        categories: [
          'Relación Interpersonal',
          'Salud',
          'Espiritualidad',
          'Recreación',
          'Finanzas',
          'Crecimiento Personal'
        ],
        max: 10
        // labels: {
        //   show: true,
        //   style: {
        //     colors: ['#a8a8a8'],
        //     fontSize: '11px',
        //     fontFamily: 'Arial'
        //   }
        // }
      },
      dataLabels: {
        style: {
          colors: [
            '#FF0000',
            '#29ABE2',
            '#5505AA',
            '#F97C00',
            '#F9E02B',
            '#009245'
          ]
        }
      },
      yaxis: {
        max: 10
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColor: '#e8e8e8',
            fill: {
              colors: ['#f8f8f8', '#fff']
            }
          }
        }
      },
      legend: {
        show: false,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'bottom',
        horizontalAlign: 'left',
        floating: true,
        fontSize: '14px',
        fontFamily: 'Helvetica, Arial',
        fontWeight: 500,
        // eslint-disable-next-line no-unused-vars
        formatter: function (seriesName, opts) {
          return seriesName //[seriesName, ' - ', opts.w.globals.series[opts.seriesIndex]]
        },
        inverseOrder: false,
        width: undefined,
        height: undefined,
        tooltipHoverFormatter: undefined,
        offsetX: 0,
        offsetY: 0,
        colors: ['red', '#29ABE2', '#5505AA', '#F97C00', '#F9E02B', '#009245'],
        labels: {
          colors: [
            '#FF0000',
            '#29ABE2',
            '#5505AA',
            '#F97C00',
            '#F9E02B',
            '#009245'
          ],
          useSeriesColors: false
        },
        markers: {
          width: 12,
          height: 12,
          strokeWidth: 0,
          strokeColor: '#fff',
          fillColors: [
            '#FF0000',
            '#29ABE2',
            '#5505AA',
            '#F97C00',
            '#F9E02B',
            '#009245'
          ],
          radius: 12,
          customHTML: undefined,
          onClick: undefined,
          offsetX: 0,
          offsetY: 0
        },
        itemMargin: {
          horizontal: 5,
          vertical: 0
        },
        onItemClick: {
          toggleDataSeries: true
        },
        onItemHover: {
          highlightDataSeries: true
        }
      }
    },
    chartMensaje: {
      chart: {
        height: 900,
        type: 'radar',
        toolbar: {
          show: false
        }
      },
      stroke: {
        colors: [
          '#FF0000',
          '#29ABE2',
          '#5505AA',
          '#F97C00',
          '#F9E02B',
          '#009245'
        ]
      },
      fill: {
        opacity: 0.9,
        colors: [
          '#FF0000',
          '#29ABE2',
          '#5505AA',
          '#F97C00',
          '#F9E02B',
          '#009245'
        ]
      },
      markers: {
        size: 0 //dejar en cero para que no aparezca un hover
      },
      xaxis: {
        labels: {
          show: false,
          style: {
            colors: [
              '#FF0000',
              '#29ABE2',
              '#5505AA',
              '#F97C00',
              '#F9E02B',
              '#009245'
            ]
          }
        },
        max: 10
      },
      yaxis: {
        max: 10
      },
      plotOptions: {
        radar: {
          polygons: {
            strokeColor: '#e8e8e8',
            fill: {
              colors: ['#f8f8f8', '#fff']
            }
          }
        }
      },
      legend: {
        show: false
      }
    },
    radar: 300,
    colors: ['#FF0000', '#29ABE2', '#5505AA', '#F97C00', '#F9E02B', '#009245'],
    colorsAreas: {
      salud: ['#ababab', '#4689bd', '#ababab', '#cccccc', '#ababab', '#cccccc'],
      espiritual: [
        '#ababab',
        '#cccccc',
        '#401686',
        '#cccccc',
        '#ababab',
        '#cccccc'
      ],
      recreacion: [
        '#ababab',
        '#cccccc',
        '#ababab',
        '#e08839',
        '#ababab',
        '#cccccc'
      ],
      interpersonal: [
        '#bc2f2a',
        '#cccccc',
        '#ababab',
        '#cccccc',
        '#ababab',
        '#cccccc'
      ],
      crecimiento: [
        '#ababab',
        '#cccccc',
        '#ababab',
        '#cccccc',
        '#ababab',
        '#63b95d'
      ],
      finanzas: [
        '#ababab',
        '#cccccc',
        '#ababab',
        '#cccccc',
        '#f4e358',
        '#cccccc'
      ]
    }
  }),
  methods: {
    chartMensajeAreas (colors, height) {
      return {
        chart: {
          height,
          type: 'radar',
          toolbar: {
            show: false
          }
        },
        stroke: {
          colors
        },
        fill: {
          opacity: 0.9,
          colors
        },
        markers: {
          size: 0 //dejar en cero para que no aparezca un hover
        },
        xaxis: {
          labels: {
            show: false,
            style: {
              colors
            }
          },
          max: 10
        },
        yaxis: {
          max: 10
        },
        plotOptions: {
          radar: {
            polygons: {
              strokeColor: '#e8e8e8',
              fill: {
                colors: ['#f8f8f8', '#fff']
              }
            }
          }
        },
        legend: {
          show: false
        }
      }
    },
    onResize () {
      this.heightFrame =
        ((document.querySelector('#videoholter').offsetWidth - 50) * 9) / 16
    },
    onresizeholter () {
      try {
        this.radar = document.querySelector('#radarHolter').offsetWidth || 300
        // eslint-disable-next-line no-empty
      } catch (error) {}
    }
  }
}
</script>
<style lang="scss" scoped>
$color_niufleex: #4d1552;
.niu-barra {
  width: 90%;
  left: 5%;
  height: 5px;
  position: absolute;
  bottom: 0;
}
.color-niufleex {
  color: $color_niufleex !important;
}
.niu-dividir-2 {
  border: solid 1px $color_niufleex !important;
}
.niu-bg-niufleex {
  background-color: $color_niufleex !important;
}
.niu-h.h-10 {
  height: 10px !important;
}
.niu-text.size45 {
  font-size: 45px !important;
}
.apexcharts-menu {
  background: #000;
}
.niu-text-card {
  font-size: 0.8rem;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  padding: 10px;
}
.apexcharts-datalabel {
  color: white;
  position: relative;
  left: 50px;
}
/* .apexcharts-legend-text */
.theme--light.v-chip:not(.v-chip--active) {
  background: white !important;
}
</style>
